import { Check, Remove } from '@mui/icons-material';

import styles from './styles.module.css';

import { Note } from './styled';
import { withStyledComponents } from './with-styled-component';
import classNames from 'classnames';
import Required from './required';

const CheckBoxLabel = withStyledComponents(styles.CheckBoxLabel, 'label');
const CheckboxVisual = withStyledComponents(styles.CheckboxVisual, 'span');

export const Checkbox = ({
  onChange,
  partial,
  checked,
  disabled,
  required,
  label,
  className,
  checkboxClassName,
  color,
  note,
}: {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  partial?: boolean;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  label?: string | JSX.Element;
  className?: string;
  checkboxClassName?: string;
  color?: string;
  note?: string;
}) => {
  const hasBackground = checked || partial;
  return (
    <>
      <CheckBoxLabel className={classNames(className, { [styles.disabled]: disabled })}>
        <input type="checkbox" disabled={disabled} checked={checked ?? false} onChange={onChange} />
        <CheckboxVisual
          className={checkboxClassName}
          style={{
            background: hasBackground ? color || '#115DFC' : undefined,
            borderColor: hasBackground ? 'transparent' : undefined,
          }}
        >
          {checked && <Check />}
          {partial && <Remove />}
        </CheckboxVisual>
        {label && (
          <span className={styles.labelWrapper}>
            {label}
            {required && <Required />}
          </span>
        )}
      </CheckBoxLabel>
      {note && <Note>{note}</Note>}
    </>
  );
};
